import React, { useRef, useState } from 'react';
import { ChatMessageTemplateProps } from '@progress/kendo-react-conversational-ui';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';

interface MessageTemplateProps extends ChatMessageTemplateProps {
  currentUserCode: string;
  hideChatButton?: boolean;
  onClickDirectChatButton?: (playerCode: string) => void;
}

export const MessageTemplate = React.memo((props: MessageTemplateProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const onClickDirectChatButton = (playerCode: string) => {
    if (props.onClickDirectChatButton) {
      props.onClickDirectChatButton(playerCode);
    }
  };

  return (
    <div className="d-flex">
      <div className="k-chat-bubble">
        <div>{props.item.text}</div>
      </div>
      {props.currentUserCode !== props.item.author.id && (
        <>
          <button
            style={{
              background: 'none',
              border: 'none'
            }}
            onClick={toggleMenu}
            ref={anchorRef}>
            <span className="material-symbols-outlined">more_horiz</span>
          </button>
          <Popup
            anchor={anchorRef.current}
            show={showMenu}
            popupClass="custom-popup chat-popup">
            <div className="dropdown-content d-flex flex-column">
              <Button>
                <div className="d-flex">
                  <span className="material-symbols-outlined mr-2">
                    partner_exchange
                  </span>
                  Trade
                </div>
              </Button>
              {!props.hideChatButton && (
                <Button
                  onClick={() => {
                    onClickDirectChatButton(props.item.author.id);
                  }}>
                  <div className="d-flex">
                    <span className="material-symbols-outlined mr-2">
                      chat_bubble
                    </span>
                    Chat
                  </div>
                </Button>
              )}
            </div>
          </Popup>
        </>
      )}
    </div>
  );
});
