import React, { useContext, useEffect, useState } from 'react';
import { GameDocumentContext } from '../../../../../contexts/game-document';
import { PlayerContext } from '../../../../../contexts/player';
import { TeamContext } from '../../../../../contexts/team';
import { Col, Container, Row } from 'react-bootstrap';
import { GetAsset } from '../../../../../utils/game-document/assets';
import { Title } from '../../../../../types/game-document/entities/title';
import { CardList } from '../../../../../components/card-list';
import { GetResourceValue } from '../../../../../utils/game-document/resources';
import { Card } from '../../../../../components/card';
import { Button } from '@progress/kendo-react-buttons';
import { GameDocument } from '../../../../../types/game-document';
import AchievementDialog from '../../../../../components/dialog/achievement-dialog';
import { PlayerStateFull, TeamStateFull } from '../../../../../types/state';
import SearchInput from '../../../../../components/input/search-input';

import { ShowRoles } from '../../../../../utils/game-engine/roles';
import { generateTitleById } from '../../../../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../../../../contexts/display-languages';

interface TitleAchievement extends Title {
  titleRes: string;
  imageRes: string;
  summaryRes: string;
}

/**
 * Loads the Title's resources and combines the result.
 * @param gameDocument
 * @param language
 * @param titleId
 */
export const GetTitleAchievement = (
  gameDocument: GameDocument,
  language: string,
  titleId: string
) => {
  let titleAsset = GetAsset<Title>(
    gameDocument.assets.titles!,
    titleId
  ) as TitleAchievement;
  titleAsset.titleRes = GetResourceValue(
    gameDocument,
    titleAsset.titleResId!,
    language
  );
  titleAsset.imageRes = GetResourceValue(
    gameDocument,
    titleAsset.imageResId!,
    language
  );
  titleAsset.summaryRes = GetResourceValue(
    gameDocument,
    titleAsset.summaryResId!,
    language
  );
  return titleAsset;
};

/**
 * Converts the Player's Titles into TitleAchievements
 * @param gameDocument
 * @param playerState
 */
const CastPlayerTitles = (
  gameDocument: GameDocument,
  playerState: PlayerStateFull
) =>
  playerState.titles!.map((title, index) =>
    GetTitleAchievement(gameDocument, playerState.language?.name! ?? '', title)
  ) ?? [];

/**
 * Converts the Player's Titles into TitleAchievements
 * @param gameDocument
 * @param teamState
 */
const CastTeamTitles = (gameDocument: GameDocument, teamState: TeamStateFull) =>
  teamState.titles!.map((title, index) =>
    GetTitleAchievement(gameDocument, '', title)
  ) ?? [];

/**
 * Search the list of TitleAchievements for the provided search term.
 * @param titles
 * @param searchTerm
 */
const SearchTitleAchievement = (
  titles: TitleAchievement[],
  searchTerm: string
) =>
  titles.filter((i) =>
    i.titleRes.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const Roles = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [playerState, setPlayerState] = useContext(PlayerContext);
  const [teamState, setTeamState] = useContext(TeamContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const [titleCard, setTitleCard] = useState<TitleAchievement | undefined>();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [playerTitles, setPlayerTitles] = useState<TitleAchievement[]>([]);
  const [teamTitles, setTeamTitles] = useState<TitleAchievement[]>([]);

  const [showRoles, setShowRoles] = useState<boolean>(true);

  // When the player's titles or search term changes. Load titles.
  useEffect(() => {
    setPlayerTitles(
      SearchTitleAchievement(
        CastPlayerTitles(gameDocument.gameDocument!, playerState.playerState!),
        searchTerm
      )
    );
  }, [playerState.playerState?.titles, searchTerm]);

  // When the team's titles or search term changes. Load titles.
  useEffect(() => {
    setTeamTitles(
      SearchTitleAchievement(
        CastTeamTitles(gameDocument.gameDocument!, teamState.teamState!),
        searchTerm
      )
    );
  }, [playerState.playerState?.titles, searchTerm]);

  useEffect(() => {
    const result = ShowRoles(
      playerState?.playerState!,
      gameDocument?.gameDocument!
    );
    setShowRoles(result!);
  }, [playerState?.playerState?.status]);

  return (
    <>
      <Container className={'drawer__body'}>
        <Row className={'mt-3 mb-4 align-items-center border-bottom pb-3'}>
          <Col>
            <h1 className={'m-0'}>
              {generateTitleById(
                '832f9ead-d125-4418-aebb-ebdff00a3a8d',
                gameDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Roles'}
            </h1>
          </Col>
          <Col sm={12} md={'auto'}>
            <SearchInput
              placeholder={'Search roles'}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.value)}
            />
          </Col>
        </Row>

        {showRoles && (
          <>
            <Row>
              <Col xs={12}>
                <Col xs={12} className={'p-4'}>
                  <div>
                    {generateTitleById(
                      'f932f99f-2e5f-4b02-9977-ba9a58764a8e',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Player titles'}
                    :
                  </div>
                </Col>
                <Col xs={12}>
                  <CardList>
                    {playerTitles &&
                      playerTitles
                        .filter((item) => !item.hideInGame)
                        .map((title, index) => {
                          return (
                            <Card
                              key={index}
                              imageUrl={title.imageRes}
                              title={title.titleRes}
                              subTitle={''}
                              onClick={() => setTitleCard(title)}></Card>
                          );
                        })}
                  </CardList>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Col xs={12} className={'p-4'}>
                  <div>
                    {generateTitleById(
                      '5c5b9991-689f-459d-99b9-0453d313f8c9',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Team titles'}
                    :
                  </div>
                </Col>
                <Col xs={12}>
                  <CardList>
                    {teamTitles &&
                      teamTitles
                        .filter((item) => !item.hideInGame)
                        .map((title, index) => {
                          return (
                            <Card
                              key={index}
                              imageUrl={title.imageRes}
                              title={title.titleRes}
                              subTitle={''}
                              onClick={() => setTitleCard(title)}></Card>
                          );
                        })}
                  </CardList>
                </Col>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Col xs={12} className={'p-4'}>
                  <div>
                    {generateTitleById(
                      'cf3a5e89-6556-4671-9593-0f930e29c58d',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Available titles'}
                    :
                  </div>
                </Col>
                <Col xs={12}>
                  <CardList>
                    {gameDocument.gameDocument?.assets.titles!.map(
                      (title, index) => {
                        let titleAsset = GetTitleAchievement(
                          gameDocument.gameDocument!,
                          playerState?.playerState?.language?.name ?? '',
                          title.id
                        );
                        return (
                          <Card
                            key={index}
                            imageUrl={titleAsset.imageRes}
                            title={titleAsset.titleRes ?? ''}
                            subTitle={''}
                            onClick={() => setTitleCard(titleAsset)}></Card>
                        );
                      }
                    )}
                  </CardList>
                </Col>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Container className={'drawer__footer p-0 text-center pb-2'} />
      {showRoles && (
        <>
          {titleCard && (
            <AchievementDialog
              imageUrl={titleCard.imageRes}
              onMaskClick={() => setTitleCard(undefined)}>
              <h1>Role</h1>
              <h2>{titleCard.titleRes}</h2>
              <p>{titleCard.summaryRes}</p>
              <div>
                <Button
                  fillMode={'outline'}
                  themeColor={'light'}
                  onClick={() => setTitleCard(undefined)}>
                  {generateTitleById(
                    '48bc820b-40e8-4624-8d46-b68e16013be6',
                    gameDocument,
                    displayLanguageContext.displayLanguageSelected.resources!,
                    'game'
                  ) || 'Close'}
                </Button>
              </div>
            </AchievementDialog>
          )}
        </>
      )}
    </>
  );
};
