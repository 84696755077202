import { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { OverlayContext } from '../../../../contexts/overlay';
import {
  ChatTab,
  DrawerTabs,
  InfoTab,
  InventoryTab,
  RolesTab,
  ScoreboardTab,
  TeamsTab
} from './tabs';
import { TabButton } from './tab-button';
import { PlayerContext } from '../../../../contexts/player';
import { GameDocumentContext } from '../../../../contexts/game-document';
import { ShowGameInfo } from '../../../../utils/game-engine/player';
import { generateTitleById } from '../../../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../../../contexts/display-languages';

interface DrawerProps {
  handleOpenChat: () => void;
}

export const Drawer = ({ handleOpenChat = () => {} }: DrawerProps) => {
  const [overlay, setOverlay] = useContext(OverlayContext);
  const [player] = useContext(PlayerContext);
  const [state] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const showGameInfoTab = ShowGameInfo(
    player?.playerState!,
    state?.gameDocument!
  );
  const gameSetting = state.gameDocument?.settings;
  const showGameLobby = gameSetting?.preGame?.showPreGameLobby ?? true;
  const showTeamsTab = gameSetting?.preGame?.showPreGameTeams ?? true;
  const showInventoryTab = gameSetting?.preGame?.showPreGameInventory ?? true;
  const showRolesTab = gameSetting?.preGame?.showPreGameRoles ?? true;
  const showChatTab = gameSetting?.preGame?.showPreGameChat ?? true;

  const showScoreTab =
    player.playerState?.status === 'playing'
      ? gameSetting?.inGame.showInGameScoreboard
      : gameSetting?.preGame.showPreGameScoreboard;

  const showDiagnosticsTab = true;

  const setActiveTab = (tab: DrawerTabs) => {
    setOverlay((prevState) => ({
      ...prevState,
      activeTab: tab
    }));

    if (tab === 'chat') {
      handleOpenChat();
    }
  };

  const toggleOverlay = () => {
    setOverlay((prevState) => ({
      ...prevState,
      drawerIsOpen: !prevState.drawerIsOpen
    }));
  };

  return (
    <>
      <Container className={'drawer__tabs p-0'}>
        <Row>
          <Col>
            <Row className={'container-menu-button'}>
              {showGameInfoTab && (
                <TabButton
                  thisTab={'info'}
                  activeTab={overlay.activeTab}
                  titleIcon={
                    generateTitleById(
                      '670d9f08-d761-4c71-883c-4265648d6a73',
                      state,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Info'
                  }
                  icon="info"
                  onSetActiveTab={setActiveTab}
                />
              )}

              {showGameLobby && showTeamsTab && (
                <TabButton
                  thisTab={'teams'}
                  activeTab={overlay.activeTab}
                  titleIcon={
                    generateTitleById(
                      '4be208b6-39fc-434d-bae7-e39f6d1ed332',
                      state,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Teams'
                  }
                  icon="group"
                  onSetActiveTab={setActiveTab}
                />
              )}

              {showGameLobby && showInventoryTab && (
                <TabButton
                  thisTab={'inventory'}
                  activeTab={overlay.activeTab}
                  titleIcon={
                    generateTitleById(
                      'f7a7c135-91e2-433f-a7ed-0b62ff39aba0',
                      state,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Inventory'
                  }
                  icon="trip"
                  onSetActiveTab={setActiveTab}
                />
              )}

              {showGameLobby && showRolesTab && (
                <TabButton
                  thisTab={'roles'}
                  activeTab={overlay.activeTab}
                  titleIcon={
                    generateTitleById(
                      '832f9ead-d125-4418-aebb-ebdff00a3a8d',
                      state,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Roles'
                  }
                  icon="settings_accessibility"
                  onSetActiveTab={setActiveTab}
                />
              )}

              {showGameLobby && showChatTab && (
                <TabButton
                  thisTab={'chat'}
                  activeTab={overlay.activeTab}
                  titleIcon={
                    generateTitleById(
                      '57f9b876-0dcf-4e59-b240-2dc88aa9390e',
                      state,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Chat'
                  }
                  icon="forum"
                  onSetActiveTab={setActiveTab}
                />
              )}
              {showScoreTab && (
                <TabButton
                  thisTab={'score'}
                  activeTab={overlay.activeTab}
                  titleIcon={
                    generateTitleById(
                      'e698200c-73e4-41e3-b4b1-fa4b38f4638c',
                      state,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Leaderboard'
                  }
                  icon="leaderboard"
                  onSetActiveTab={setActiveTab}
                />
              )}

              {/* #6572 hide these 2 button  */}
              {/* <TabButton
                thisTab={'predownload'}
                activeTab={overlay.activeTab}
                titleIcon={
                  generateTitleByDisplayId('021', player.playerState!, state) ||
                  'Pre-download'
                }
                onSetActiveTab={setActiveTab}
              />

              {showDiagnosticsTab && (
                <TabButton
                  thisTab={'diagnostics'}
                  activeTab={overlay.activeTab}
                  titleIcon={
                    generateTitleByDisplayId(
                      '022',
                      player.playerState!,
                      state
                    ) || 'Diagnostics'
                  }
                  onSetActiveTab={setActiveTab}
                />
              )} */}
            </Row>
          </Col>
          <Col
            sm={12}
            md={'auto'}
            className={'d-flex align-items-center justify-content-end'}>
            <Button fillMode={'flat'} onClick={toggleOverlay}>
              <span
                className={'material-symbols-outlined text-white'}
                style={{ fontSize: '3rem' }}>
                expand_less
              </span>
            </Button>
          </Col>
        </Row>
      </Container>
      {overlay.activeTab === 'info' && <InfoTab />}
      {overlay.activeTab === 'teams' && showTeamsTab && <TeamsTab />}
      {overlay.activeTab === 'inventory' && showInventoryTab && (
        <InventoryTab />
      )}
      {overlay.activeTab === 'roles' && showRolesTab && <RolesTab />}
      {overlay.activeTab === 'chat' && showChatTab && <ChatTab />}
      {overlay.activeTab === 'score' && showScoreTab && <ScoreboardTab />}
      {/* #6572 hide these 2 button  */}
      {/* {overlay.activeTab === 'predownload' && <PreDownloadTab />}
      {overlay.activeTab === 'diagnostics' && showDiagnosticsTab && (
        <DiagnosticsTab />
      )} */}
    </>
  );
};
